import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_form_line = _resolveComponent("tm-form-line")!

  return (_openBlock(), _createBlock(_component_tm_form_line, {
    "left-label": !_ctx.isSmMax,
    "left-label-width": "150px",
    class: _normalizeClass(["wizard-form-line", {
      'wizard-form-line--top-label': _ctx.topLabel
    }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["left-label", "class"]))
}