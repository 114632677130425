
import { defineComponent } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmFormLine,
  },
  props: {
    topLabel: {
      type: Boolean,
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    return {
      isSmMax,
    }
  },
})
